import React from 'react';

import './Announcement.scss'

import {randomize, STAFF_IMAGES} from '../../data';

function Announcement(props){

	const staff = Object.keys(STAFF_IMAGES).filter(name => props[name]);

	const classes = [
		'slide',
		'slide-announcement',
		'position-'+props.Position.toLowerCase(),
		props.Style ? 'style-'+props.Style.toLowerCase() : 'Normal'
	];

	return(
		<div className={classes.filter(c=>c).join(' ')}>
			<div className="announcement-container">
				<p dangerouslySetInnerHTML={{__html:props.Announcement.replace(/\n/,'<br>').split(' ').map((word, index)=>'<span style="animation-delay:'+index*.3+'s">'+word+'</span>').join(' ')}}></p>
				{props.Secondary && <p style={{
					animationDelay:''+(props.Announcement.split(' ').length*.3+1)+'s'
				}}>{props.Secondary}</p>}
			</div>
			<ul id="staff" className={staff.length > 4 || (props.position !== 'Top' && staff.length > 3 ) ? 'animated' : null}>
				{staff.length > 0 && randomize(staff).map(name => <li key={"A" + name}>
					<img src={STAFF_IMAGES[name]} alt=""/>
				</li>)}
			</ul>
		</div>
	);

}

export default Announcement;