import React, {useEffect, useRef} from "react";

import {ReactComponent as CorpsLogo} from '../images/logo-corps.svg';

function SlidelessArea(props) {

	//based on https://codepen.io/Web_Cifar/pen/JjXrLRJ

	const logo = useRef();
	const area = useRef();

	useEffect(()=>{

		if( !logo.current || !area.current ){
			return;
		}

		const areaWidth = area.current.offsetWidth;
		const areaHeight = area.current.offsetHeight;

		const logoWidth = logo.current.clientWidth;
		const logoHeight = logo.current.clientHeight;

		// console.log(logo, areaWidth, areaHeight, logoWidth, logoHeight);

		let xSpeed = 3;
		let ySpeed = 3;

		const staringXSeed = props.className === 'top' ? 1200 : 500;
		let xPosition = Math.floor(Math.random() * (staringXSeed - 10 + 1) + 10);
		let yPosition = Math.floor(Math.random() * (1200 - 10 + 1) + 10);
		logo.current.style.left = xPosition+'px';
		logo.current.style.top = yPosition+'px';

		const colors = ['#ffffff', '#000000'];
		let currentColor = 0;

		const getNextColor = () => {
			currentColor = currentColor >= colors.length - 1 ? 0 : currentColor+1;
			return colors[currentColor];
		}

		const update = () =>{

			if (xPosition + logoWidth >= areaWidth || xPosition <= 0) {
				xSpeed = -xSpeed;
				logo.current.style.color = getNextColor();
			}
			if (yPosition + logoHeight >= areaHeight || yPosition <= 0) {
				ySpeed = -ySpeed;
				logo.current.style.color = getNextColor();
			}

			xPosition += xSpeed;
			yPosition += ySpeed;

			logo.current.style.left = xPosition+'px';
			logo.current.style.top = yPosition+'px';

			requestAnimationFrame(update);
		};

		requestAnimationFrame(update);

		return () => {
			cancelAnimationFrame(update);
		}


	},[props.className])

	return (
		<div ref={area} className={[props.className, 'slideless'].join(' ')}>
			<CorpsLogo ref={logo} />
		</div>
	);
}

export default SlidelessArea;