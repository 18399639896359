import React, {useEffect, useRef, useState} from 'react';

import {toHTML} from 'slack-markdown'

import './QuotableCorps.css';
import background from "../../images/QuotableCorps.png"

function QuotableCorps(props){

	const [quoteParts, setQuoteParts] = useState({});
	const [typeCounter, setTypeCounter] = useState(0);

	const slideRef = useRef(null);

	useEffect(()=>{
		const regex = /\b\s+ -| -| –| —|"-|"-|"–|"—\s+/;
		const parts = props.message.split(regex);
		const quoteParts = {};

		const HTMLConversionSettings = {
			slackOnly:true,
			escapeHTML:false
		};
		quoteParts.quote = parts[0] ? toHTML(parts[0].trim().replace(/^"|“|”/,'').replace(/"|“|”$/,'') + '”', HTMLConversionSettings) : null;
		quoteParts.attribution = parts[1] ? toHTML(parts[1].trim(), HTMLConversionSettings) : null;

		setQuoteParts(quoteParts);

		let wordIndex = 0;
		let transitionTimeout = null;
		const wordInterval = setInterval(()=>{
			wordIndex++;
			setTypeCounter(wordIndex);
			if( wordIndex === quoteParts.quote.split('').length - 1 ){
				clearInterval(wordInterval);
				transitionTimeout = setTimeout(()=>{
					slideRef.current.dispatchEvent(new Event('slide_complete',{ bubbles:true, cancellable:false, composed:false}));
				}, 5000);
			}
		}, Math.floor(Math.random() * (100 - 25 + 1) + 25));

		return () => {
			clearInterval(wordInterval);
			if( transitionTimeout !== null ){
				clearTimeout(transitionTimeout);
			}
		};

	}, [props]);

	return (
		<div ref={slideRef} className="slide-quotable-corps">
			<div className="background"/>
			<div className="background"/>
			<img src={background} alt=""/>
			<blockquote>
				{quoteParts.quote && <p className={typeCounter < quoteParts.quote.split('').length - 1 ? 'typing' : null} dangerouslySetInnerHTML={{__html: quoteParts.quote.split('').slice(0,typeCounter).join('')}}></p>}
				{quoteParts.quote && <p className="placeholder" dangerouslySetInnerHTML={{__html: quoteParts.quote}}></p>}
				{quoteParts.attribution && <cite dangerouslySetInnerHTML={{__html: quoteParts.attribution}}></cite>}
			</blockquote>
		</div>
	);
}

export default QuotableCorps;