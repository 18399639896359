export const parseSheetsData = data =>{
	const rows = [];

	if( !data || !data.values ){
		return rows;
	}

	data.values.forEach((row, rowIndex)=>{
		if( rowIndex === 0 || row[0] === '' ){
			return;
		}
		const rowData = {};
		data.values[0].forEach((column, index) => {
			let value = row[index];

			//typing
			if( value && value.trim() ){
				if( value.toLowerCase() === 'true' || row[index].toLowerCase() === 'false' ){
					//boolean type coercion
					value = JSON.parse(value.toLowerCase());
				} else if( value.length === 10 && /\d{4}-\d{2}-\d{2}/.test(value) ){
					//date type coercion
					value = new Date(value);
				} else if( !isNaN(Number(value)) ){
					//number type coercion
					value = Number(value);
				}
			} else {
				value = null;
			}

			rowData[column] = value;
		});
		rows.push(rowData);
	});
	return rows;
};

const sheetsEndpoint = 'https://sheets.googleapis.com/v4/spreadsheets/{SHEETID}/values/{RANGE}';

export const buildURL = (key, sheetID, range, dimension = 'rows') => {
	if( !['columns','rows'].includes(dimension) ){
		return '';
	}
	return sheetsEndpoint.replace('{SHEETID}',sheetID).replace('{RANGE}',range)+'?majorDimension='+dimension.toUpperCase()+'&key='+key;
}
