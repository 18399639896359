import React from 'react';

import aquariusImage from '../../images/zodiac-icons/aquarius.svg';
import ariesImage from '../../images/zodiac-icons/aries.svg';
import cancerImage from '../../images/zodiac-icons/cancer.svg';
import capricornImage from '../../images/zodiac-icons/capricorn.svg';
import geminiImage from '../../images/zodiac-icons/gemini.svg';
import leoImage from '../../images/zodiac-icons/leo.svg';
import libraImage from '../../images/zodiac-icons/libra.svg';
import piscesImage from '../../images/zodiac-icons/pisces.svg';
import sagittariusImage from '../../images/zodiac-icons/sagittarius.svg';
import scorpioImage from '../../images/zodiac-icons/scorpio.svg';
import taurusImage from '../../images/zodiac-icons/taurus.svg';
import virgoImage from '../../images/zodiac-icons/virgo.svg';

import './Birthday.css'

function Birthday(props){

	const ZODIAC_IMAGES = {
		aquarius : aquariusImage,
		aries : ariesImage,
		cancer : cancerImage,
		capricorn : capricornImage,
		gemini : geminiImage,
		leo : leoImage,
		libra : libraImage,
		pisces : piscesImage,
		sagittarius : sagittariusImage,
		scorpio : scorpioImage,
		taurus : taurusImage,
		virgo : virgoImage
	};

	const findZodiac = (month, day) => {
		if ((month === 1 && day <= 20) || (month === 12 && day >= 22)) {
			return "Capricorn";
		} else if ((month === 1 && day >= 21) || (month === 2 && day <= 19)) {
			return "Aquarius";
		} else if ((month === 2 && day >= 20) || (month === 3 && day <= 20)) {
			return "Pisces";
		} else if ((month === 3 && day >= 21) || (month === 4 && day <= 20)) {
			return "Aries";
		} else if ((month === 4 && day >= 21) || (month === 5 && day <= 21)) {
			return "Taurus";
		} else if ((month === 5 && day >= 22) || (month === 6 && day <= 21)) {
			return "Gemini";
		} else if ((month === 6 && day >= 22) || (month === 7 && day <= 22)) {
			return "Cancer";
		} else if ((month === 7 && day >= 23) || (month === 8 && day <= 22)) {
			return "Leo";
		} else if ((month === 8 && day >= 23) || (month === 9 && day <= 23)) {
			return "Virgo";
		} else if ((month === 9 && day >= 24) || (month === 10 && day <= 23)) {
			return "Libra";
		} else if ((month === 10 && day >= 24) || (month === 11 && day <= 22)) {
			return "Scorpio";
		} else if ((month === 11 && day >= 23) || (month === 12 && day <= 21)) {
			return "Sagittarius";
		}
	};

	const zodiacSign = findZodiac(props.birthday.month, props.birthday.day);

	const suffix = (props.birthday.age_suffix !== '') ? props.birthday.age_suffix : 'th';

	return (
		<div className="slide slide-birthday">
			<h1>LET'S <br/> CELEBRATE</h1>
			<h2 className="happy">HAPPY</h2>
			<h2 id="age">{props.birthday.age}<sup>{suffix}</sup></h2>
			<img id="image" src={props.image} alt=""/>
			<div>
				<h2 id="name">{props.first} {props.last.substring(0,1)}.</h2>
				<h3 id="zodiac"><img src={ZODIAC_IMAGES[zodiacSign.toLowerCase()]} alt=""/> {zodiacSign}</h3>
			</div>

		</div>
	);
}

export default Birthday;